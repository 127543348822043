import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { updateUser } from "../../../lib/backendClient";
import { retrieveUser } from "../../../lib/backendClient";
import { useEffect } from "react";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditQuestions() {
  const navigate = useNavigate();
  const location = useLocation();

  const register_questions = async () => {
    let update = await updateUser({
      answer1: answer1,
      answer2: answer2,
      answer3: answer3,
    });
    return update;
  };

  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.answer1) setAnswer1(profile?.answer1);
      if (profile?.answer2) setAnswer2(profile?.answer2);
      if (profile?.answer3) setAnswer3(profile?.answer3);
    });
  }, []);

  const isAnswerValid = (answer) => answer.trim().length >= 3;

  const isFormComplete =
    isAnswerValid(answer1) && isAnswerValid(answer2) && isAnswerValid(answer3);

  return (
    <EditFormLayout
      title="Persönliche Fragen"
      actionDisabled={!isFormComplete}
      action={register_questions}
      actionText={"Ok"}
    >
      <div className="col-12 question">
        <div className="task-description">
          Nimm dir Zeit und beantworte die Fragen ehrlich.
        </div>
        <div className="task-description-question">
          Erzähle über einen besonderen Moment in den letzten 5 Jahren.
        </div>
        <div className="personal-status mb-3">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              maxLength={128}
              onChange={(e) => setAnswer1(e.target.value)}
              value={answer1}
            />
          </div>
          <small className="text-muted">
            {answer1.length}/128 Zeichen verwendet
          </small>
        </div>
      </div>
      <div className="col-12 question">
        <div className="task-description-question">
          Was ist dein Lieblingsreiseziel?
        </div>
        <div className="personal-status  mb-3">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              maxLength={128}
              onChange={(e) => setAnswer2(e.target.value)}
              value={answer2}
            />
          </div>
          <small className="text-muted">
            {answer2.length}/128 Zeichen verwendet
          </small>
        </div>
      </div>
      <div className="col-12 question">
        <div className="task-description-question">
          Wie sieht dein typisches Wochenende aus?
        </div>
        <div className="personal-status  mb-3">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              maxLength={128}
              onChange={(e) => setAnswer3(e.target.value)}
              value={answer3}
            />
          </div>
          <small className="text-muted">
            {answer3.length}/128 Zeichen verwendet
          </small>
        </div>
      </div>
    </EditFormLayout>
  );
}
