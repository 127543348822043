import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { verifyMail, registerMail, login } from "../lib/backendClient";
import RegisterFormLayout from "./layout/registerFormLayout";
export default function Mail_Verification_Otp() {
  const [otp, setOtp] = React.useState(new Array(5).fill("")); // Initialize an array for OTP input values
  const [active, setActiveButton] = React.useState(true);

  const navigate = useNavigate();

  const location = useLocation();
  let { email, password } = location.state || {};

  const mail_resend = async () => {
    let mailSent = await registerMail(email, password);
    if (mailSent) {
      alert("Mail erneut versendet");
    }
  };

  const register = async () => {
    let verified = await verifyMail(email, otp.join(""));
    let auth;
    if (verified) {
      auth = await login(email, password);
    }
    if (auth)
      navigate("/create_profile", { state: { email: email }, replace: true });
  };

  const handleChange = (element, index) => {
    const value = element.value.replace(/\D/g, ""); // Ensure only numeric input
    if (value.length > 1) return; // Prevent multiple characters

    const newOtp = [...otp];
    newOtp[index] = value; // Update the OTP array
    setOtp(newOtp);

    // Move focus to the next input if the value is a digit
    if (value && element.nextSibling) {
      element.nextSibling.focus();
    } else {
      setActiveButton(false);
    }
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      actionDisabled={active}
      action={register}
      actionText={"Registrieren"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Verifizierung</h1>
        <div className="task-description">
          Wir haben dir einen Verifizierungscode an deine E-Mail-Adresse
          gesendet.
        </div>
        <div className="task-description">Gib diesen Code hier ein.</div>
        <div className="input-group otp mb-3">
          {otp.map((data, index) => (
            <input
              key={index}
              type="text"
              className="form-control"
              inputMode="numeric"
              maxLength="1"
              value={data}
              onChange={(e) => handleChange(e.target, index)}
              onFocus={(e) => e.target.select()}
              aria-label={`OTP digit ${index + 1}`}
            />
          ))}
        </div>
        <div className="text-center">
          <a onClick={mail_resend} className="email-confirmation-resend">
            Code erneut versenden?
          </a>
        </div>
      </div>
    </RegisterFormLayout>
  );
}
