import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  loadReligions,
  updateUser,
  retrieveUser,
} from "../../../lib/backendClient";
import EditFormLayout from "../../layout/editFormLayout";
export default function EditReligion() {
  const navigate = useNavigate();

  const [disabled, setDisabled] = React.useState(true);
  const [religion, setReligion] = React.useState();
  const location = useLocation();

  const register_status = async () => {
    let update = await updateUser({ religion: religion });
    return update;
  };

  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    loadReligions().then((religions) => setOptions(religions));
    retrieveUser().then((profile) => {
      if (profile?.religion) {
        setReligion(profile?.religion);
        setDisabled(false);
      }
    });
  }, []);

  return (
    <EditFormLayout
      title="Religion"
      actionDisabled={disabled}
      action={register_status}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Welcher Religion gehörst du an?</h1>
        <div className="task-description">
          Bitte gib deine Religion ein. Diese Information hilft dabei,
          Übereinstimmungen in Glaubensfragen zu finden.
        </div>
      </div>
      <div className="hstack flex-wrap gap-2">
        {options.map((option, index) => (
          <button
            key={index}
            onClick={() => {
              setReligion(option.id);
              setDisabled(false);
            }}
            className={`btn btn-${religion === option.id ? "primary" : "secondary"} py-2`}
          >
            {option.name} {option.symbol}
          </button>
        ))}
      </div>
    </EditFormLayout>
  );
}
