import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Location() {
  const navigate = useNavigate();
  const [disabled, setActiveButton] = useState(true);
  const [userLocation, setUserLocation] = useState("");
  const [userLocationLat, setUserLocationLat] = useState(0.0);
  const [userLocationLng, setUserLocationLng] = useState(0.0);
  const [locationCountryCode, setLocationCountryCode] = useState("");

  const location = useLocation();
  const inputRef = useRef(null);

  // Initialize Google Maps Autocomplete
  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["geocode"],
          componentRestrictions: { country: ["DE", "AT", "CH"] }, // Restrict to DACH region
        },
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          setUserLocation(
            place.address_components.find(
              (component) =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_1") ||
                component.types.includes("administrative_area_level_2"),
            )?.long_name,
          );
          setUserLocationLat(
            parseFloat(place.geometry.location.lat().toFixed(7)),
          );
          setUserLocationLng(
            parseFloat(place.geometry.location.lng().toFixed(7)),
          );

          // Extract the country code from address components
          const addressComponents = place.address_components;
          const countryComponent = addressComponents.find((component) =>
            component.types.includes("country"),
          );
          if (countryComponent) {
            setLocationCountryCode(countryComponent.short_name);
          }

          setActiveButton(false);
        }
      });
    }
    retrieveUser().then((profile) => {
      if (profile?.location) {
        setUserLocation(profile?.location);
        setActiveButton(false);
      }
      if (profile?.location_lat) {
        setUserLocationLat(profile?.location_lat);
      }
      if (profile?.location_lng) {
        setUserLocationLng(profile?.location_lng);
      }
      if (profile?.location_country_code) {
        setLocationCountryCode(profile?.location_country_code);
      }
    });
  }, []);

  const create_distance = async () => {
    let update = await updateUser({
      location: userLocation,
      location_lat: userLocationLat,
      location_lng: userLocationLng,
      location_country_code: locationCountryCode,
    });
    if (update) {
      navigate("/create_distance", {
        state: { ...location.state },
      }); // Navigate to the next page when button is clicked
    }
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={55}
      actionDisabled={
        disabled || !userLocation || !userLocationLat || !userLocationLng
      }
      action={create_distance}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Wo lebst du?</h1>
        <div className="task-description">
          Da die Paalam-Mitglieder aus der DACH-Region kommen, ist dein Wohnort
          eine wichtige Information für die Partnersuche.
        </div>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="mail">
          <i className="bi bi-search"></i>
        </span>
        <input
          type="text"
          ref={inputRef}
          className="form-control"
          placeholder="Ort eingeben"
          aria-label="mail"
          aria-describedby="basic-addon1"
          value={userLocation}
          onChange={(e) => {
            setUserLocationLng(0.0);
            setUserLocationLat(0.0);
            setUserLocation(e.target.value);
          }}
        />
      </div>
    </RegisterFormLayout>
  );
}
