import React from "react";
import { useNavigate } from "react-router-dom";

export default function EditFormLayout({
  children,
  title,
  actionDisabled = true,
  action = async () => {},
  actionText,
}) {
  const navigate = useNavigate();
  const back = () => navigate(-1);
  const actionClick = async () => {
    if (await action()) back();
  };

  return (
    <div className="app">
      <div className="container top-element">
        <div className="row controls mb-4">
          <div className="col-3">
            <i onClick={back} className="bi fs-4 bi-x" />
          </div>
          <div className="col-6">
            <div className="action">{title}</div>
          </div>
        </div>
      </div>
      <div className="container content-element">
        <div className="row">{children}</div>
      </div>
      <div className="container bottom-element">
        <div className="row">
          <div className="col">
            <button
              disabled={actionDisabled}
              onClick={actionClick}
              className="btn btn-primary w-100 py-2"
            >
              {actionText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
