import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { updateUser } from "../../../lib/backendClient";
import { retrieveUser } from "../../../lib/backendClient";
import { useEffect } from "react";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditEducation() {
  const navigate = useNavigate();

  const location = useLocation();
  const register_profession = async () => {
    let update = await updateUser({
      education: optionState,
    });
    return update;
  };

  const options = [
    { label: "Fachausbildung", showInput: false },
    { label: "Hochschulreife", showInput: false },
    { label: "Bachelor", showInput: false },
    { label: "Master", showInput: false },
    { label: "Promotion", showInput: false },
    { label: "Andere", showInput: true },
  ];

  const [optionState, setOptionState] = useState(null);

  const [showOptionInput, setShowOptionInput] = useState(false);

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.education) {
        setOptionState(profile?.education);
        setShowOptionInput(
          !options.some((op) => op.label === profile?.education),
        );
      }
    });
  }, []);

  return (
    <EditFormLayout
      title="Bildungsabschluss"
      actionDisabled={!optionState}
      action={register_profession}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Was ist dein höchster Bildungsabschluss?</h1>
        <div className="task-description">
          Bitte gib dein Bildungsniveau an. Dies ist für andere Mitglieder
          sichtbar.
        </div>
      </div>
      <div className="personal-status">
        {options.map((option, index) => {
          return (
            <div className="form-check" key={`education_${index}`}>
              <input
                className="form-check-input"
                type="radio"
                name="funnelRadio"
                id={`funnel${index}`}
                onChange={() => {
                  if (option.showInput) setOptionState(null);
                  else setOptionState(option.label);
                  setShowOptionInput(option.showInput);
                }}
                checked={
                  option.label === optionState ||
                  (!options.some((op) => op.label === optionState) &&
                    option.showInput)
                }
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                {option.label}
              </label>
            </div>
          );
        })}
        {showOptionInput && (
          <div className="input-group mb-3">
            <span className="input-group-text" id="mail"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              value={optionState}
              onChange={(e) => {
                setOptionState(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </EditFormLayout>
  );
}
