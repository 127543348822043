import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Name() {
  const [disabled, setActiveButton] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const create_distance = async () => {
    let update = await updateUser({
      first_name: firstName,
      last_name: lastName,
    });
    if (update)
      navigate("/create_gender", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.first_name) {
        setFirstName(profile?.first_name);
      }
      if (profile?.last_name) {
        setLastName(profile?.last_name);
      }
      if (profile?.first_name && profile?.last_name) {
        setActiveButton(false);
      }
    });
  }, []);

  const isButtonDisabled = !(firstName && lastName);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={22}
      actionDisabled={isButtonDisabled}
      action={create_distance}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Wie heisst du?</h1>
        <div className="task-description">
          Deine Privatsphäre steht bei Paalam an erster Stelle. Dein Nachname
          ist für andere Mitglieder nicht sichtbar.
        </div>
      </div>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Dein Vorname"
          aria-label="Dein Vorname"
          aria-describedby="basic-addon1"
          onChange={(e) => {
            setFirstName(e.target.value);
            if (lastName) setActiveButton(false);
          }}
          value={firstName}
        />{" "}
        <input
          type="text"
          className="form-control mx-2"
          placeholder="Dein Nachname"
          aria-label="Dein Nachname"
          aria-describedby="basic-addon1"
          onChange={(e) => {
            setLastName(e.target.value);
            if (firstName) setActiveButton(false);
          }}
          value={lastName}
        />
      </div>
    </RegisterFormLayout>
  );
}
