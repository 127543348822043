import React from "react";
import { useNavigate } from "react-router-dom";

export default function RegisterFormLayout({
  children,
  progress = undefined,
  isNavigationBackShown = false,
  isActionButtonShown = true,
  actionDisabled = true,
  action,
  actionText,
}) {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  return (
    <div className="app">
      <div className="container top-element">
        <div className="row controls mb-4">
          <div className="col-3">
            {isNavigationBackShown && (
              <i onClick={back} className="bi fs-4 bi-arrow-left" />
            )}
          </div>
          <div className="col-6">
            {progress !== undefined && (
              <div
                className="progress"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="0"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  className="progress-bar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container content-element">
        <div className="row">{children}</div>
      </div>
      {isActionButtonShown && (
        <div className="container bottom-element">
          <div className="row">
            <div className="col">
              <button
                disabled={actionDisabled}
                onClick={action}
                className="btn btn-primary w-100 py-2"
              >
                {actionText}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
