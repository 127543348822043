const profileRoutes = [
  { condition: (user) => !user.first_name, path: "/create_profile" },
  { condition: (user) => !user.gender, path: "/create_gender" },
  {
    condition: (user) => user.date_of_birth === "1900-01-01",
    path: "/create_birthday",
  },
  { condition: (user) => !user.location, path: "/create_location" },
  { condition: (user) => !user.distance, path: "/create_distance" },
  {
    condition: (user, profilePictures) => !profilePictures.length,
    path: "/create_pictures",
  },
  { condition: (user) => !user.stripe_is_active, path: "/create_payment" },
  { condition: (user) => !user.intention, path: "/create_prelaunch" },
  { condition: (user) => !user.height, path: "/register_info" },
  {
    condition: (user) => !user.religion && user.religion !== 1,
    path: "/register_religion",
  },
  { condition: (user) => !user.marital_status, path: "/register_status" },
  { condition: (user) => !user.kids, path: "/register_children" },
  { condition: (user) => !user.kids_wish, path: "/register_childrenWish" },
  { condition: (user) => !user.languages?.length, path: "/register_languages" },
  { condition: (user) => !user.education, path: "/register_education" },
  { condition: (user) => !user.profession, path: "/register_profession" },
  { condition: (user) => !user.smoking, path: "/register_smoking" },
  { condition: (user) => !user.alcohol, path: "/register_alcohol" },
  {
    condition: (user) => !user.personality_traits?.length,
    path: "/register_personality",
  },
  { condition: (user) => !user.interests?.length, path: "/register_interests" },
  { condition: (user) => !user.cuisines?.length, path: "/register_kitchen" },
  {
    condition: (user) => !user.answer1 || !user.answer2 || !user.answer3,
    path: "/register_questions",
  },
];

// Function to get the navigation path based on the first unmet condition
export function getNavigationPath(user, profilePictures) {
  return (
    profileRoutes.find((route) => route.condition(user, profilePictures))
      ?.path || "/swipe"
  );
}

export default profileRoutes;
