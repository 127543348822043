import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Gender() {
  const navigate = useNavigate();

  const [gender, setGender] = React.useState(null);
  const updateGender = (value) => setGender(value);

  const location = useLocation();
  const create_birthday = async () => {
    let update = await updateUser({ gender: gender });
    if (update) navigate("/create_birthday", { state: { ...location.state } });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.gender) {
        setGender(profile?.gender);
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={33}
      actionDisabled={gender === null}
      action={create_birthday}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Bist du eine Frau oder ein Mann?</h1>
      </div>
      <div className="d-grid gap-3">
        <button
          onClick={() => updateGender("W")}
          className={`btn btn-${gender === "W" ? "primary" : "secondary"} w-100 py-2`}
        >
          Frau
        </button>
        <button
          onClick={() => updateGender("M")}
          className={`btn btn-${gender === "M" ? "primary" : "secondary"} w-100 py-2`}
        >
          Mann
        </button>
      </div>
    </RegisterFormLayout>
  );
}
