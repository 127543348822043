import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import { loadMatches, deleteMatch, loadChats } from "../../lib/backendClient";

export default function Matches() {
  const [matches, setMatches] = useState([]);
  const [chats, setChats] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  const matchProfile = async (matchUserId) => {
    navigate("/profile", { state: { userId: matchUserId } });
  };

  const deleteMatchEntry = (id) => {
    const confirmDelete = window.confirm(
      "Willst du den Match wirklich löschen?",
    );
    if (confirmDelete) {
      deleteMatch(id);
      setMatches((prevItems) => prevItems.filter((item) => item.id !== id));
    }
  };

  useEffect(() => {
    loadMatches().then((matchData) => {
      setMatches(matchData);
      setLoaded(true);
    });
    loadChats().then((chatData) => {
      setChats(chatData);
    });
  }, []);

  return (
    <AppLayout
      title={"Matches"}
      isNavigationBackShown={true}
      //isSettingsButtonShown={false}
      //settingsIcon="bi-sliders"
      //settingsAction,
    >
      <div className="row g-2">
        {!loaded && (
          <div
            className="spinner-grow"
            style={{ color: "var(--p-purple)" }}
            role="status"
          />
        )}
        {loaded && matches.length === 0 ? (
          <p>Du hast noch keine Matches.</p>
        ) : (
          matches.map((match) => (
            <div className="col-6" key={match.id}>
              <div
                className={`match ${
                  chats.find((item) =>
                    item.participants.some(
                      (participant) => participant.id === match?.user?.id,
                    ),
                  )?.block
                    ? "block"
                    : ""
                }`}
              >
                <div
                  className="picture-delete"
                  onClick={() => deleteMatchEntry(match?.id)}
                >
                  <i className="bi bi-x"></i>
                </div>
                <img
                  src={match?.user?.profile_picture?.image}
                  alt=""
                  className="match-img"
                  onClick={() => {
                    if (
                      !chats.find((item) =>
                        item.participants.some(
                          (participant) => participant.id === match?.user?.id,
                        ),
                      )?.block
                    )
                      matchProfile(match?.user?.id);
                  }}
                />
                <div className="overlay"></div>
                <div className="match-information">
                  <div className="match-name">{match?.user.first_name}</div>
                  {!chats.find((item) =>
                    item.participants.some(
                      (participant) => participant.id === match?.user?.id,
                    ),
                  )?.block && (
                    <div className="match-chat">
                      <button
                        className="action-element"
                        onClick={(event) => {
                          event.stopPropagation();
                          let matchChat = chats.find((item) =>
                            item.participants.some(
                              (participant) =>
                                participant.id === match?.user?.id,
                            ),
                          );
                          if (!matchChat?.block)
                            navigate("/chat", { state: { chat: matchChat } });
                        }}
                      >
                        <i className="fs-5 bi bi-chat"></i>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </AppLayout>
  );
}
