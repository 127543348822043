import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { registerMail } from "../lib/backendClient";
import { z } from "zod";
import RegisterFormLayout from "./layout/registerFormLayout";

const passwordSchema = z
  .string()
  .min(8, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[A-Z]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[a-z]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[0-9]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[^a-zA-Z0-9]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  });

const emailSchema = z
  .string()
  .email({ message: "Die E-Mail-Adresse ist ungültig." });

function Register() {
  const [email, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleMailChange = (event) => {
    const inputEmail = event.target.value;
    setMail(inputEmail);

    try {
      emailSchema.parse(inputEmail);
      setEmailError(null); // No error if validation passes
    } catch (error) {
      if (error instanceof z.ZodError) {
        setEmailError(error.errors[0].message); // Show email validation error
      }
    }
  };

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    setPassword(inputPassword);

    try {
      passwordSchema.parse(inputPassword);
      setPasswordError(null); // No error if validation passes
    } catch (error) {
      if (error instanceof z.ZodError) {
        setPasswordError(error.errors[0].message); // Show password validation error
      }
    }
  };

  const navigate = useNavigate();

  const mail_verification_information = async () => {
    // Check if there are no current validation errors
    if (!emailError && !passwordError) {
      let mailSent = await registerMail(email, password);
      if (mailSent) {
        navigate("/mail_verification_information", {
          state: { email: email, password: password },
        });
      }
    }
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      actionDisabled={emailError || passwordError}
      action={mail_verification_information}
      actionText={"Registrieren"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Registrieren</h1>
        <div className="task-description">
          Erstelle deinen Account in wenigen Sekunden. Wir finden deinen Match.
        </div>

        {/* Email Input */}
        <label htmlFor="" className="form-label">
          E-Mail
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="mail">
            <i className="bi bi-envelope-fill"></i>
          </span>
          <input
            type="text"
            className={`form-control ${emailError ? "is-invalid" : ""}`}
            placeholder="E-Mail"
            aria-label="E-Mail"
            aria-describedby="basic-addon1"
            value={email}
            onChange={handleMailChange}
          />
        </div>
        {emailError && <div className="alert alert-danger">{emailError}</div>}

        {/* Password Input */}
        <label htmlFor="" className="form-label">
          Passwort
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password">
            <i className="bi bi-lock-fill"></i>
          </span>
          <input
            type={showPassword ? "text" : "password"}
            className={`form-control ${passwordError ? "is-invalid" : ""}`}
            placeholder="Passwort"
            aria-label="Passwort"
            aria-describedby="basic-addon1"
            value={password}
            onChange={handlePasswordChange}
          />
          <div className="input-group-text" onClick={toggleShowPassword}>
            <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`} />
          </div>
        </div>
        {passwordError && (
          <div className="alert alert-danger">{passwordError}</div>
        )}

        <p className="account-create-information">
          Durch das Fortfahren akzeptierst du unsere{" "}
          <a
            href="https://paalam-tamil.com/de/agb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGB
          </a>{" "}
          und
          <a
            href="https://paalam-tamil.com/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Datenschutzbestimmung
          </a>
          .
        </p>
        <hr />
        <div className="d-flex justify-content-center">
          <p className="account-login">Hast du schon einen Account?</p>
          <Link to="/login" relative="path">
            Login
          </Link>
        </div>
      </div>
    </RegisterFormLayout>
  );
}

export default Register;
