// AudioRecorder.js
import React, { useState } from "react";

const AudioRecorder = ({ onUpload, disabled = false }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  const startRecording = async () => {
    setIsRecording(true);
    const stream = await navigator.mediaDevices.getUserMedia(
      { audio: true },
      { mimeType: "audio/mp4" },
    );

    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);

    const audioChunks = [];
    recorder.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    recorder.onstop = async () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/mp4" }); // Change type to WAV
      await onUpload(audioBlob); // Call the upload handler
      setIsRecording(false); // Reset recording state
    };

    recorder.start();
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
  };

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <button
      className="btn btn-send"
      onClick={toggleRecording}
      style={{
        backgroundColor: isRecording ? "#DC3545" : "",
        color: isRecording ? "white" : "black",
      }}
      disabled={disabled}
    >
      <i className={`fs-5 bi ${isRecording ? "bi-pause-circle" : "bi-mic"}`} />
    </button>
  );
};

export default AudioRecorder;
