import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSprings, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import AppLayout from "../layout/appLayout";
import {
  match,
  retrieveCandidates,
  requestChat,
  loadVerificationUrl,
  loadChats,
} from "../../lib/backendClient";

const mockData = [
  {
    id: false,
    first_name: "",
    age: "",
    profile_picture: { image: "/placeholder.svg" },
  },
];

function SwipePage({ reset }) {
  const [candidates, setCandidates] = useState([]);
  const [unverified, setUnverified] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState("");
  const [swiping, setSwiping] = useState(false);
  const navigate = useNavigate();

  const [springs, api] = useSprings(candidates.length, () => ({
    x: 0,
    y: 0,
    rotation: 0,
    scale: 1,
    zIndex: 0,
  }));

  const animateSwipe = (
    index,
    { active = false, mx = 0, velocity = 0, xDir = 1 } = {},
  ) => {
    const trigger = velocity > 0.2;
    const dir = xDir < 0 ? -1 : 1;

    console.log(active, mx, velocity, xDir);
    setSwiping(xDir !== 0);

    if (!active && trigger) {
      // Trigger swipe out animation
      api.start((i) => {
        if (i !== index) return;
        return {
          x: dir * 500,
          rotation: dir * 50,
          config: { friction: 50, tension: 200 },
        };
      });
      like(dir > 0);
    } else {
      api.start((i) => {
        if (i !== index) return;
        return {
          x: active ? mx : 0,
          rotation: active ? mx / 10 : 0,
          zIndex: active ? 1 : 0,
          config: { friction: 50, tension: active ? 800 : 500 },
        };
      });
    }
  };

  const bind = useDrag(
    ({
      args: [index],
      active,
      movement: [mx],
      direction: [xDir],
      velocity,
    }) => {
      animateSwipe(index, { active, mx, velocity: velocity[0], xDir });
    },
  );

  const triggerSwipe = (index, options) => {
    animateSwipe(index, options);
  };

  const swipeFilter = () => {
    navigate("/swipeFilter");
  };

  const matchChat = async (userId) => {
    return await requestChat(userId);
  };

  const profile = (matchUserId) => {
    navigate("/profile", { state: { userId: matchUserId } });
  };

  const like = async (direction) => {
    if (candidates.length > 0) {
      await match(
        candidates[candidates.length - 1].id,
        direction ? "like" : "dislike",
      );
      const newItems = candidates.slice(0, -1);
      setCandidates(newItems);
    }
    if (candidates.length <= 1) {
      setTimeout(() => reset(), 500);
    }
  };

  const loadCandidates = async () => {
    const candidateData = await retrieveCandidates();
    let newCandidates;
    if (candidateData === "unverified") {
      setUnverified(true);
      newCandidates = mockData;
      setVerificationUrl(await loadVerificationUrl());
    } else {
      newCandidates = candidateData?.length > 0 ? candidateData : mockData;
    }
    setCandidates(newCandidates);
  };

  useEffect(() => {
    loadCandidates();
  }, []);

  return (
    <AppLayout
      title={
        <img
          src={require("../../static/images/paalam_horizontal_b.png")}
          alt="Paalam Logo"
          className="chat-logo"
        />
      }
      isSettingsButtonShown={true}
      settingsIcon={"bi-sliders"}
      settingsAction={swipeFilter}
    >
      <div className="row swipe-view">
        {unverified && (
          <div
            className="modal fade show"
            id="exampleModalCenter"
            tabIndex={-1}
            aria-labelledby="exampleModalCenterTitle"
            aria-modal="true"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body">
                  <p>
                    Paalam erlaubt nur verifizierte Nutzer. Verifiziere deine
                    Identität, um zu matchen.
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    onClick={(event) => {
                      event.stopPropagation();
                      navigate("/profile");
                    }}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Mein Profil
                  </button>
                  <button
                    onClick={() =>
                      (window.location.href = verificationUrl?.url)
                    }
                    type="button"
                    className="btn btn-primary"
                  >
                    Jetzt verifizieren
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-12 cardContainer">
          {springs.map((props, i) => (
            <animated.div
              key={candidates[i]?.id || i}
              className="swipeCard swipe"
              style={{
                ...props,
                touchAction: "none",
              }}
              {...bind(i)}
            >
              <div className="person">
                <div className="person-image">
                  {!candidates[i]?.id && (
                    <div className="match-next">
                      <strong>
                        Du hast alle verfügbaren Profile geswipet.
                      </strong>
                      Erweitere dein Suchfilter, um weitere Tamil*innen
                      angezeigt zu bekommen.
                    </div>
                  )}
                  {candidates[i]?.id && (
                    <img
                      src={candidates[i]?.profile_picture?.image}
                      alt={`${candidates[i].first_name}`}
                    />
                  )}
                </div>
                {candidates !== mockData && <div className="overlay" />}
                {candidates[i]?.id && (
                  <div
                    className="swipe-information pressable"
                    onClick={(event) => {
                      event.stopPropagation();
                      if (!swiping) profile(candidates[i].id);
                    }}
                  >
                    <div className="person-information">
                      <div className="person-name">
                        {candidates[i].first_name}, {candidates[i].age}
                      </div>
                    </div>
                    <div
                      className="actions"
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <button
                        className="action-element"
                        id="dislikeButton"
                        onClick={(event) => {
                          event.stopPropagation();
                          triggerSwipe(i, {
                            mx: -300,
                            velocity: 0.21,
                            xDir: -1,
                          });
                        }}
                      >
                        <i className="bi bi-x-lg pressable" />
                      </button>
                      <button
                        className="action-element "
                        onClick={async (event) => {
                          event.stopPropagation();
                          if (await matchChat(candidates[i]?.id)) {
                            let chats = await loadChats();
                            let chat = chats.reduce((latest, item) => {
                              return new Date(item.date_added) >
                                new Date(latest.date_added)
                                ? item
                                : latest;
                            });
                            navigate("/chat", { state: { chat: chat } });
                          }
                        }}
                      >
                        <i className="fs-4 bi bi-chat-heart pressable" />
                      </button>
                      <button
                        className="action-element"
                        id="likeButton"
                        onClick={(event) => {
                          event.stopPropagation();
                          triggerSwipe(i, { mx: 300, velocity: 0.21, xDir: 1 });
                        }}
                      >
                        <i className="bi bi-heart-fill pressable"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </animated.div>
          ))}
        </div>
      </div>
    </AppLayout>
  );
}

export default function Swipe() {
  const [componentKey, setComponentKey] = useState(0);

  const resetComponent = () => {
    setComponentKey((prevKey) => prevKey + 1);
  };

  return <SwipePage key={componentKey} reset={resetComponent} />;
}
