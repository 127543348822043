import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";
import { useEffect } from "react";
import { updateUser } from "../../lib/backendClient";
import { retrieveUser, loadPersonalities } from "../../lib/backendClient";

export default function Personality() {
  const navigate = useNavigate();

  const location = useLocation();
  const register_interests = async () => {
    let update = await updateUser({
      personality_traits: [...personalityState],
    });
    if (update)
      navigate("/register_interests", {
        state: { ...location.state },
      });
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [personalityState, setPersonalityState] = React.useState(new Set());

  const addPersonality = (personality) => {
    setPersonalityState((prevPersonality) => {
      const updatedPersonality = new Set(prevPersonality);

      if (updatedPersonality.has(personality)) {
        updatedPersonality.delete(personality);
      } else {
        if (updatedPersonality.size < 3) updatedPersonality.add(personality);
      }

      return updatedPersonality;
    });
  };

  useEffect(() => {
    loadPersonalities().then((languages) => {
      setAvailableOptions(languages);
    });
    retrieveUser().then((profile) => {
      if (profile?.personality_traits) {
        setPersonalityState(new Set(profile?.personality_traits));
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={86}
      actionDisabled={personalityState.size === 0}
      action={register_interests}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Persönlichkeit</h1>
        <div className="task-description">
          Wähle bis zu drei Eigenschaften, die dich am besten beschreiben. Es
          sind bis zu 3 auszuwählen.
        </div>
      </div>
      <div className="hstack flex-wrap mt-2 gap-2">
        {avaliableOptions.map((option) => (
          <button
            key={`${option.id}-${personalityState.has(option.id)}`}
            className={`btn ${personalityState.has(option.id) ? "btn-primary" : "btn-secondary"} py-2`}
            onClick={(event) => {
              addPersonality(option.id);
            }}
          >
            {option.name}
          </button>
        ))}
      </div>
    </RegisterFormLayout>
  );
}
