import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";
import { updateUser } from "../../lib/backendClient";

export default function Create_Prelaunch() {
  const navigate = useNavigate();

  const intentions = [
    { label: "Freundschaften", showInput: false },
    { label: "Beziehung", showInput: false },
    { label: "Andere", showInput: true },
  ];
  const funnels = [
    { label: "Social Media", showInput: false },
    { label: "Google", showInput: false },
    { label: "Empfehlung", showInput: false },
    { label: "Andere", showInput: true },
  ];

  const [showIntentionInput, setShowIntentionInput] = useState(false);
  const [showFunnelInput, setShowFunnelInput] = useState(false);
  const [intentionState, setIntentionState] = useState(null);
  const [funnelState, setFunnelState] = useState(null);

  const create_summary = async () => {
    let update = await updateUser({
      intention: intentionState,
      find_out: funnelState,
    });
    if (update) navigate("/register_info");
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={100}
      actionDisabled={!funnelState || !intentionState}
      action={create_summary}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <div className="task-description">
          Paalam ist eine Plattform für tamilische Singles in der DACH-Region,
          die auf der Suche nach ernsthaften und langfristigen Beziehungen sind.
          Um sicherzustellen, dass unsere Mitglieder dieselben Ziele teilen,
          bitten wir dich, uns mitzuteilen:
        </div>
      </div>
      <div className="personal-status mb-3">
        <div className="task-description">
          Mit welcher Absicht bist du auf Paalam?
        </div>
        {intentions.map((intention, index) => {
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="intentionsRadio"
                id={`intention${index}`}
                onChange={() => {
                  if (intention.showInput) setIntentionState(null);
                  else setIntentionState(intention.label);
                  setShowIntentionInput(intention.showInput);
                }}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                {intention.label}
              </label>
            </div>
          );
        })}

        {showIntentionInput && (
          <div className="input-group mb-3">
            <span className="input-group-text" id="mail"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              onChange={(e) => {
                setIntentionState(e.target.value);
              }}
            />
          </div>
        )}
      </div>
      <div className="personal-status">
        <div className="task-description">
          Wie bist du auf Paalam aufmerksam geworden?
        </div>
        {funnels.map((funnel, index) => {
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="funnelRadio"
                id={`funnel${index}`}
                onChange={() => {
                  if (funnel.showInput) setFunnelState(null);
                  else setFunnelState(funnel.label);
                  setShowFunnelInput(funnel.showInput);
                }}
              />
              <label className="form-check-label" for="flexRadioDefault1">
                {funnel.label}
              </label>
            </div>
          );
        })}
        {showFunnelInput && (
          <div className="input-group mb-3">
            <span className="input-group-text" id="mail"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              onChange={(e) => {
                setFunnelState(e.target.value);
              }}
            />
          </div>
        )}
      </div>
    </RegisterFormLayout>
  );
}
