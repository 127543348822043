import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Info() {
  const navigate = useNavigate();

  const location = useLocation();
  const register_height = () => {
    navigate("/register_height", {
      state: { ...location.state },
    });
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={false}
      progress={50}
      actionDisabled={false}
      action={register_height}
      actionText={"Profil erstellen"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Lass uns dein Profil erstellen!</h1>
        <div className="task-description">
          Du hast dich erfolgreich registriert – das ist der erste Schritt zu
          neuen, bedeutungsvollen Verbindungen.
        </div>
        <div className="task-description">
          Nimm dir bitte etwas Zeit, um dein Profil zu vervollständigen. So
          erhöhen wir die Chance, dass du Menschen triffst, die wirklich zu dir
          passen.
        </div>
        <div className="task-description">
          Viel Erfolg bei der Suche nach einer besonderen Beziehung!
        </div>
      </div>
    </RegisterFormLayout>
  );
}
