import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { updateUser } from "../../lib/backendClient";
import { retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function ChildrenWish() {
  const navigate = useNavigate();

  const [disabled, setDisabled] = React.useState(true);
  const [kidsWish, setKidsWish] = React.useState();
  const location = useLocation();
  const register_languages = async () => {
    let update = await updateUser({ kids_wish: kidsWish });
    if (update)
      navigate("/register_languages", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.kids_wish) {
        setKidsWish(profile?.kids_wish);
        setDisabled(false);
      }
    });
  }, []);

  const options = [
    { title: "Ich möchte Kinder.", key: "yes" },
    { title: "Ich möchte keine Kinder", key: "no" },
    { title: "Ich möchte diese Information nicht teilen.", key: "secret" },
  ];

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={66}
      actionDisabled={disabled}
      action={register_languages}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Kinderwunsch?</h1>
        <div className="task-description">
          Kinderwünsche, eigene Kinder und Kinder des Partners spielen sehr
          häufig eine grosse Rolle.
        </div>
      </div>
      <div className="personal-status">
        {options.map(({ title, key }, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id={`flexRadioDefault${key}`}
              onChange={() => {
                setKidsWish(key);
                setDisabled(false);
              }}
              checked={key === kidsWish}
            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault${key}`}
            >
              {title}
            </label>
          </div>
        ))}
      </div>
    </RegisterFormLayout>
  );
}
