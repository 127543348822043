import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import {
  loadCuisines,
  loadInterests,
  loadLanguages,
  loadReligions,
  loadPersonalities,
  retrieveProfilePictures,
  retrieveUser,
  logout,
} from "../../lib/backendClient";
import { mapEnumToName, mapStatusEnumToName } from "../../lib/utils";

export default function EditProfile() {
  const navigate = useNavigate();

  const [profile, setProfile] = useState();
  const [interestOptions, setInterestOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [religionOptions, setReligionOptions] = useState([]);
  const [cuisineOptions, setCuisineOptions] = useState([]);
  const [personalityOptions, setPersonalityOptions] = useState([]);
  const [profilePictures, setProfilePictures] = useState([]);

  useEffect(() => {
    loadInterests().then((interestOptionData) => {
      setInterestOptions(interestOptionData);
    });

    loadLanguages().then((languageOptionData) => {
      setLanguageOptions(languageOptionData);
    });

    loadReligions().then((religionOptionData) => {
      setReligionOptions(religionOptionData);
    });

    loadCuisines().then((cuisineOptionData) => {
      setCuisineOptions(cuisineOptionData);
    });

    loadPersonalities().then((personalityOptionData) => {
      setPersonalityOptions(personalityOptionData);
    });

    retrieveProfilePictures().then((data) => {
      setProfilePictures(data);
    });

    retrieveUser().then((userData) => {
      setProfile(userData);
    });
  }, []);

  const editPictures = () => {
    navigate("/edit/pictures"); // Navigate to the "About" page when button is clicked
  };

  const editHeight = () => {
    navigate("/edit/height"); // Navigate to the "About" page when button is clicked
  };

  const editProfession = () => {
    navigate("/edit/profession"); // Navigate to the "About" page when button is clicked
  };

  const editLocation = () => {
    navigate("/edit/location"); // Navigate to the "About" page when button is clicked
  };

  const editQuestions = () => {
    navigate("/edit/questions");
  };

  const editInterests = () => {
    navigate("/edit/interests"); // Navigate to the "About" page when button is clicked
  };

  const editReligion = () => {
    navigate("/edit/religion");
  };

  const editLanguages = () => {
    navigate("/edit/languages");
  };

  const editEducation = () => {
    navigate("/edit/education");
  };

  const editKids = () => {
    navigate("/edit/kids");
  };
  const editKidsWish = () => {
    navigate("/edit/kidsWish");
  };

  const editSmoking = () => {
    navigate("/edit/smoking");
  };
  const editAlcohol = () => {
    navigate("/edit/alcohol");
  };
  const editCuisine = () => {
    navigate("/edit/cuisine");
  };

  const editPersonality = () => {
    navigate("/edit/personality");
  };

  const editStatus = () => {
    navigate("/edit/status");
  };

  const stripeCustomerPortalURL =
    process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL;

  const stripeVerifyURL = process.env.REACT_APP_STRIPE_VERIFY_URL;

  return (
    <AppLayout title={"Profil bearbeiten"} isNavigationBackShown={true}>
      <div className="row g-2 mb-4" onClick={editPictures}>
        {profilePictures?.map((picture, index) => (
          <div className="col-4 picture-element" key={`image_${index}`}>
            <div className="picture-delete">
              <i className="bi bi-x"></i>
            </div>
            <div
              className="picture"
              style={{
                background: `url("${picture.image}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            />
          </div>
        ))}
        {Array(6 - profilePictures?.length)
          .fill(1)
          .map((_, index) => (
            <div
              className="col-4 picture-element"
              key={`image_placeholder_${index}`}
            >
              <div className="picture">
                <i className="bi bi-plus-lg"></i>
              </div>
            </div>
          ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading">
              <div className="information-group-title">Allgemein</div>
            </div>
            <hr />
            <div className="information-group-content">
              <div className="information-element-list" onClick={editHeight}>
                <div className="information-element-title">
                  <div className="element-title">Grösse</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.height}cm</div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div
                className="information-element-list"
                onClick={editProfession}
              >
                <div className="information-element-title">
                  <div className="element-title">Beruf</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.profession}</div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editStatus}>
                <div className="information-element-title">
                  <div className="element-title">Lebensstatus</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {mapStatusEnumToName(profile?.marital_status)}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editLocation}>
                <div className="information-element-title">
                  <div className="element-title">Wohnort</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {profile?.location_complete}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading" onClick={editQuestions}>
              <div className="information-group-title">Persönliche Fragen</div>
              <i className="bi bi-chevron-right"></i>
            </div>
            <hr />
            <div className="information-group-content questions">
              <div className="information-element-list">
                <div className="information-element-title">
                  <div className="element-title">Besonderer Moment</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.answer1}</div>
                </div>
              </div>
              <div className="information-element-list">
                <div className="information-element-title">
                  <div className="element-title">Lieblingsreiseziel</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.answer2}</div>
                </div>
              </div>
              <div className="information-element-list">
                <div className="information-element-title">
                  <div className="element-title">Typisches Wochenende</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.answer3}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading" onClick={editInterests}>
              <div className="information-group-title">Interessen</div>
              <i className="bi bi-chevron-right"></i>
            </div>
            <hr />
            <div className="information-group-content list">
              {profile?.interests.map((interest, index) => {
                const interestData = interestOptions?.find(
                  (io) => io.id === interest,
                );
                return (
                  <div
                    className="information-element"
                    key={`interest_${index}`}
                  >
                    {interestData?.name} {interestData?.symbol}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading" onClick={editLanguages}>
              <div className="information-group-title">Sprachen</div>
              <i className="bi bi-chevron-right"></i>
            </div>
            <hr />
            <div className="information-group-content list">
              {profile?.languages.map((language, index) => {
                const languageData = languageOptions?.find(
                  (io) => io.id === language,
                );
                return (
                  <div
                    className="information-element"
                    key={`language_${index}`}
                  >
                    {languageData?.symbol} {languageData?.name}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading" onClick={editReligion}>
              <div className="information-group-title">Religion</div>
              <i className="bi bi-chevron-right"></i>
            </div>
            <hr />
            <div className="information-group-content list">
              {
                <div className="information-element">
                  {
                    religionOptions?.find((io) => io.id === profile?.religion)
                      ?.name
                  }{" "}
                  {
                    religionOptions?.find((io) => io.id === profile?.religion)
                      ?.symbol
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading">
              <div className="information-group-title">Details</div>
            </div>
            <hr />
            <div className="information-group-content">
              <div className="information-element-list" onClick={editEducation}>
                <div className="information-element-title">
                  <div className="element-title">Ausbildung</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">{profile?.education}</div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editKids}>
                <div className="information-element-title">
                  <div className="element-title">Kinder</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {mapEnumToName(profile?.kids)}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editKidsWish}>
                <div className="information-element-title">
                  <div className="element-title">Kinderwunsch</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {mapEnumToName(profile?.kids_wish)}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div
              className="information-group-heading"
              onClick={editPersonality}
            >
              <div className="information-group-title">
                Persönlichkeitsmerkmale
              </div>
              <i className="bi bi-chevron-right"></i>
            </div>
            <hr />
            <div className="information-group-content list">
              {profile?.personality_traits.map((personality, index) => {
                const personalityData = personalityOptions?.find(
                  (io) => io.id === personality,
                );
                return (
                  <div
                    className="information-element"
                    key={`personality_trait_${index}`}
                  >
                    {personalityData?.name} {personalityData?.symbol}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="profile-information-group mb-3">
            <div className="information-group-heading">
              <div className="information-group-title">Lifestyle</div>
            </div>
            <hr />
            <div className="information-group-content">
              <div className="information-element-list" onClick={editAlcohol}>
                <div className="information-element-title">
                  <div className="element-title">Trinken</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {mapEnumToName(profile?.alcohol)}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editSmoking}>
                <div className="information-element-title">
                  <div className="element-title">Rauchen</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {mapEnumToName(profile?.smoking)}
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
              <div className="information-element-list" onClick={editCuisine}>
                <div className="information-element-title">
                  <div className="element-title">Cuisine</div>
                </div>
                <div className="information-element-selection">
                  <div className="element-selection">
                    {
                      cuisineOptions?.find(
                        (io) => io.id === profile?.cuisines[0],
                      )?.name
                    }
                    , ...
                  </div>
                  <i className="bi bi-chevron-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-12">
          <button onClick={logout} className={`btn btn-secondary w-100 py-2`}>
            Logout
          </button>
        </div>
      </div> */}
      <div className="row">
        <div className="col-12">
          <button
            onClick={() => (window.location.href = stripeCustomerPortalURL)}
            className={`btn btn-delete w-100 py-2`}
          >
            Mitgliedschaft beenden
          </button>
        </div>
      </div>
    </AppLayout>
  );
}
