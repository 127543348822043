import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";

import RegisterFormLayout from "../layout/registerFormLayout";

const calculateAge = (birthday) => {
  try {
    var diff_ms =
      Date.now() -
      new Date(
        Number(birthday[2]),
        Number(birthday[1]) - 1,
        Number(birthday[0]),
      ).getTime();
    var age_dt = new Date(diff_ms);
    age_dt.setDate(age_dt.getDate() - 1);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  } catch {
    return 0;
  }
};

export default function Create_Birthday() {
  const navigate = useNavigate();
  const [active, setActiveButton] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [birthday, setBirthday] = React.useState(new Array(3).fill(0));
  const [age, setAge] = React.useState(0);

  const isValidDate = (year, month, day) => {
    const testDate = new Date(year, month - 1, day);

    return (
      testDate.getFullYear() == year &&
      testDate.getMonth() + 1 == month &&
      testDate.getDate() == day
    );
  };

  const handleAgeCheck = (newBirthday) => {
    if (newBirthday.every((val) => val.length > 0)) {
      const newAge = calculateAge(newBirthday);
      setAge(newAge);

      if (!isValidDate(newBirthday[2], newBirthday[1], newBirthday[0])) {
        setActiveButton(true);
        setErrorMessage("Bitte gib ein gültiges Geburtsdatum an.");
      } else if (newAge >= 25) {
        setActiveButton(false);
        setErrorMessage("");
      } else {
        setActiveButton(true);
        setErrorMessage(
          "Es tut uns leid, aber unser Dienst richtet sich an Personen ab 25 Jahren.",
        );
      }
    } else {
      // Clear error message if not all fields are filled
      setErrorMessage("");
    }
  };

  const handleChange = (element, index) => {
    const newBirthday = [...birthday];
    newBirthday[index] = element.value;
    setBirthday(newBirthday);

    handleAgeCheck(newBirthday);
  };

  const location = useLocation();

  const create_location = async () => {
    let update = await updateUser({
      date_of_birth: `${birthday[2]}-${birthday[1]}-${birthday[0]}`,
    });
    if (update) navigate("/create_location", { state: { ...location.state } });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.date_of_birth && profile?.date_of_birth !== "1900-01-01") {
        const newBirthday = profile?.date_of_birth.split("-").reverse();
        setBirthday(newBirthday);
        handleAgeCheck(newBirthday);
        setActiveButton(false);
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={44}
      actionDisabled={active}
      action={create_location}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Wann bist du geboren?</h1>
        <div className="task-description">
          Das Alter ist für viele tamilische Singles bei der Partnersuche ein
          wichtiges Kriterium. Dein genaues Geburtsdatum bleibt vertraulich -
          andere Mitglieder sehen lediglich Dein Alter.
        </div>
      </div>
      <div className="input-group birthday mb-3">
        <select
          className="form-select"
          aria-label="day"
          onChange={(e) => handleChange(e.target, 0)}
          value={birthday[0] ? birthday[0] : undefined}
        >
          <option value="" selected disabled hidden>
            TT
          </option>
          {Array(31)
            .fill()
            .map((_, i) => {
              const currentDay = i + 1;
              return (
                <option value={currentDay} key={`day${currentDay}`}>
                  {currentDay}
                </option>
              );
            })}
        </select>
        <select
          className="form-select"
          aria-label="month"
          onChange={(e) => handleChange(e.target, 1)}
          value={birthday[1] ? birthday[1] : undefined}
        >
          <option value="" selected disabled hidden>
            MM
          </option>
          {Array(12)
            .fill()
            .map((_, i) => {
              const currentMonth = i + 1;
              return (
                <option value={currentMonth} key={`month${currentMonth}`}>
                  {currentMonth}
                </option>
              );
            })}
        </select>
        <select
          className="form-select"
          aria-label="year"
          onChange={(e) => handleChange(e.target, 2)}
          value={birthday[2] ? birthday[2] : undefined}
        >
          <option value="" selected disabled hidden>
            JJJJ
          </option>
          {Array(80)
            .fill()
            .map((_, i) => {
              const baseYear = new Date().getFullYear() - 80;

              const currentYear = i + 1 + baseYear;
              return (
                <option value={currentYear} key={`year${currentYear}`}>
                  {currentYear}
                </option>
              );
            })
            .reverse()}
        </select>
      </div>
      {age ? (
        <div className="task-description">
          Alter <strong>{age}</strong> - Diese Angabe kann später nicht geändert
          werden.
        </div>
      ) : (
        ""
      )}
      {errorMessage && (
        <div className="alert alert-danger mt-2" role="alert">
          {errorMessage}
        </div>
      )}
    </RegisterFormLayout>
  );
}
