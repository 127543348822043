import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  loadInterests,
  updateUser,
  retrieveUser,
} from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Interests() {
  const navigate = useNavigate();

  const [interests, setInterests] = React.useState(new Set());

  const location = useLocation();

  const register_kitchen = async () => {
    let update = await updateUser({ interests: [...interests] });
    if (update)
      navigate("/register_kitchen", {
        state: { ...location.state },
      });
  };

  const addInterest = (interest) => {
    setInterests((prevInterests) => {
      const updatedInterests = new Set(prevInterests);

      if (updatedInterests.has(interest)) {
        updatedInterests.delete(interest);
      } else {
        if (updatedInterests.size < 3) updatedInterests.add(interest);
      }

      return updatedInterests;
    });
  };

  const filter = (event) => {
    setFilteredOptions(
      avaliableOptions.filter((option) =>
        option.name.startsWith(event.target.value),
      ),
    );
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  useEffect(() => {
    loadInterests().then((loadedInterests) => {
      setAvailableOptions(loadedInterests);
      setFilteredOptions(loadedInterests);
    });
    retrieveUser().then((profile) => {
      if (profile?.interests) {
        setInterests(new Set(profile?.interests));
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={90}
      actionDisabled={interests.size === 0}
      action={register_kitchen}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Finde Personen mit ähnlichen Interessen</h1>
        <div className="task-description">
          Wähle bis zu drei Interessen, denen du regelmässig nachgehst.
        </div>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="mail">
          <i className="bi bi-search"></i>
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Interessen suchen"
          aria-label="interests"
          aria-describedby="interests"
          onChange={filter}
        />
      </div>
      <div className="hstack flex-wrap mt-2 gap-2">
        {filteredOptions.map((option) => (
          <button
            key={`${option.id}-${interests.has(option.id)}`}
            className={`btn ${interests.has(option.id) ? "btn-primary" : "btn-secondary"} py-2`}
            onClick={(event) => {
              addInterest(option.id);
            }}
          >
            {option.name} {option.symbol}
          </button>
        ))}
      </div>
    </RegisterFormLayout>
  );
}
