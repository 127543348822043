const responseEnum = {
  yes: "ja",
  no: "nein",
  sometimes: "manchmal",
  secret: "geheim",
};

export function mapEnumToName(value) {
  return responseEnum[value];
}

const statusEnum = {
  single: "noch nie verheiratet",
  divorced: "geschieden",
  separated: "getrennt",
  widow: "verwitwet",
  secret: "geheim",
};

export function mapStatusEnumToName(value) {
  return statusEnum[value];
}
