import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./pages/layout/scrolltotop";
import Welcome from "./pages/welcome";
import Register from "./pages/register";
import Login from "./pages/login";
import Mail_Verification_Information from "./pages/mail_verification_information";
import Mail_Verification_Otp from "./pages/mail_verification_otp";
import Create_Profile from "./pages/profile/create";
import Create_Name from "./pages/profile/name";
import Create_Gender from "./pages/profile/gender";
import Create_Birthday from "./pages/profile/birthday";
import Create_Location from "./pages/profile/location";
import Create_Distance from "./pages/profile/distance";
import Create_Status from "./pages/profile/status";
import Create_Pictures from "./pages/profile/pictures";
import Create_Payment from "./pages/profile/payment";
import PasswordReset from "./pages/password_reset";
import Prelaunch from "./pages/profile/prelaunch";

import Information from "./pages/profile/information";
import Height from "./pages/profile/height";
import Religion from "./pages/profile/religion";
import Alcohol from "./pages/profile/alcohol";
import Children from "./pages/profile/children";
import ChildrenWish from "./pages/profile/childWish";
import Education from "./pages/profile/education";
import Kitchen from "./pages/profile/kitchen";
import Languages from "./pages/profile/languages";
import Personality from "./pages/profile/personality";
import Profession from "./pages/profile/profession";
import Interests from "./pages/profile/interests";
import Questions from "./pages/profile/questions";
import Smoking from "./pages/profile/smoking";
import Error404 from "./error404";
import Create_Summary from "./pages/profile/summary";
import Swipe from "./pages/app/swipe";

import { useEffect } from "react";
import SwipeFilter from "./pages/app/swipeFilter";
import Matches from "./pages/app/matches";
import Chats from "./pages/app/chats";
import ChatView from "./pages/app/chatView";
import ChatOptions from "./pages/app/chatOptions";
import Profile from "./pages/app/profile";
import EditProfile from "./pages/app/editProfile";
import EditAlcohol from "./pages/profile/edit/alcohol";
import EditSmoking from "./pages/profile/edit/smoking";
import EditCuisine from "./pages/profile/edit/cuisine";
import EditKidsWish from "./pages/profile/edit/kidsWish";
import EditKids from "./pages/profile/edit/kids";
import EditQuestions from "./pages/profile/edit/questions";
import EditEducation from "./pages/profile/edit/education";
import EditReligion from "./pages/profile/edit/religion";
import EditLanguages from "./pages/profile/edit/languages";
import EditInterests from "./pages/profile/edit/interests";
import EditLocation from "./pages/profile/edit/location";
import EditProfession from "./pages/profile/edit/profession";
import EditHeight from "./pages/profile/edit/height";
import EditPictures from "./pages/profile/edit/pictures";
import EditStatus from "./pages/profile/edit/status";
import EditPersonality from "./pages/profile/edit/personality";

// Function to dynamically load Google Maps script
const loadGoogleMapsScript = (apiKey) => {
  // Check if the script is already added
  if (
    !document.querySelector(
      `script[src="https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places"]`,
    )
  ) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }
};

function App() {
  useEffect(() => {
    const apiKey = process.env.REACT_APP_MAPS_API_KEY;
    if (apiKey) {
      loadGoogleMapsScript(apiKey);
    } else {
      console.error("Google Maps API key not found in environment variables");
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/">
          <Route path="*" element={<Error404 />} />
          <Route index element={<Welcome />} />
          <Route path="login" element={<Login />} />
          <Route
            path="/reset_password/:uidb64/:token"
            element={<PasswordReset />}
          />
          <Route path="register" element={<Register />} />
          <Route
            path="mail_verification_information"
            element={<Mail_Verification_Information />}
          />
          <Route
            path="mail_verification_otp"
            element={<Mail_Verification_Otp />}
          />
          <Route path="create_profile" element={<Create_Profile />} />
          <Route path="create_name" element={<Create_Name />} />
          <Route path="create_gender" element={<Create_Gender />} />
          <Route path="create_birthday" element={<Create_Birthday />} />
          <Route path="create_location" element={<Create_Location />} />
          <Route path="create_distance" element={<Create_Distance />} />
          <Route path="create_pictures" element={<Create_Pictures />} />
          <Route path="create_payment" element={<Create_Payment />} />
          <Route path="create_prelaunch" element={<Prelaunch />} />
          <Route path="create_summary" element={<Create_Summary />} />

          {/* Launch */}

          <Route path="register_info" element={<Information />} />
          <Route path="register_height" element={<Height />} />
          <Route path="register_religion" element={<Religion />} />
          <Route path="register_status" element={<Create_Status />} />
          <Route path="register_children" element={<Children />} />
          <Route path="register_childrenWish" element={<ChildrenWish />} />
          <Route path="register_languages" element={<Languages />} />
          <Route path="register_education" element={<Education />} />
          <Route path="register_profession" element={<Profession />} />
          <Route path="register_smoking" element={<Smoking />} />
          <Route path="register_alcohol" element={<Alcohol />} />
          <Route path="register_personality" element={<Personality />} />
          <Route path="register_interests" element={<Interests />} />
          <Route path="register_kitchen" element={<Kitchen />} />
          <Route path="register_questions" element={<Questions />} />

          <Route path="swipe" element={<Swipe />} />
          <Route path="swipeFilter" element={<SwipeFilter />} />
          <Route path="matches" element={<Matches />} />
          <Route path="chats" element={<Chats />} />
          <Route path="chat" element={<ChatView />} />
          <Route path="chatOptions" element={<ChatOptions />} />
          <Route path="profile" element={<Profile />} />
          <Route path="edit" element={<EditProfile />} />

          {/* Edit Profile */}
          <Route path="edit/alcohol" element={<EditAlcohol />} />
          <Route path="edit/smoking" element={<EditSmoking />} />
          <Route path="edit/cuisine" element={<EditCuisine />} />
          <Route path="edit/kidsWish" element={<EditKidsWish />} />
          <Route path="edit/kids" element={<EditKids />} />
          <Route path="edit/questions" element={<EditQuestions />} />
          <Route path="edit/education" element={<EditEducation />} />
          <Route path="edit/religion" element={<EditReligion />} />
          <Route path="edit/languages" element={<EditLanguages />} />
          <Route path="edit/interests" element={<EditInterests />} />
          <Route path="edit/location" element={<EditLocation />} />
          <Route path="edit/profession" element={<EditProfession />} />
          <Route path="edit/personality" element={<EditPersonality />} />
          <Route path="edit/height" element={<EditHeight />} />
          <Route path="edit/pictures" element={<EditPictures />} />
          <Route path="edit/status" element={<EditStatus />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
