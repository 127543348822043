import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { updatePassword } from "../lib/backendClient";
import { z } from "zod";
import RegisterFormLayout from "./layout/registerFormLayout";

const passwordSchema = z
  .string()
  .min(8, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[A-Z]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[a-z]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[0-9]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  })
  .regex(/[^a-zA-Z0-9]/, {
    message:
      "Dein Passwort sollte mindestens 8 Zeichen haben und Grossbuchstaben, Kleinbuchstaben, Ziffern und Sonderzeichen enthalten.",
  });

export default function PasswordReset() {
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = React.useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const { uidb64, token } = useParams();

  const handlePasswordChange = (event) => {
    const inputPassword = event.target.value;
    setNewPassword(inputPassword);

    try {
      passwordSchema.parse(inputPassword);
      setPasswordError(null); // No error if validation passes
    } catch (error) {
      if (error instanceof z.ZodError) {
        setPasswordError(error.errors[0].message); // Show password validation error
      }
    }
  };

  const resetPasswort = async () => {
    handlePasswordChange({ target: { value: newPassword } });
    if (!passwordError) {
      let update = await updatePassword(uidb64, token, newPassword);
      if (update)
        navigate("/login", {
          state: { ...location.state },
          replace: true,
        });
    }
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={false}
      actionDisabled={passwordError}
      action={resetPasswort}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Passwort zurücksetzen</h1>
        <div className="task-description">
          Wie soll dein neues Passwort heissen?
        </div>
      </div>
      <div className="input-group mb-3">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          placeholder="Passwort"
          aria-label="Passwort"
          aria-describedby="basic-addon1"
          onChange={handlePasswordChange}
          value={newPassword}
        />
        <div className="input-group-text" onClick={toggleShowPassword}>
          <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`} />
        </div>
      </div>
      {passwordError && (
        <div className="alert alert-danger">{passwordError}</div>
      )}
    </RegisterFormLayout>
  );
}
