import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Questions() {
  const navigate = useNavigate();

  const location = useLocation();
  const lets_go = async () => {
    let update = await updateUser({
      answer1: answer1,
      answer2: answer2,
      answer3: answer3,
    });
    if (update)
      navigate("/swipe", {
        state: { ...location.state },
      });
  };

  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.answer1) setAnswer1(profile?.answer1);
      if (profile?.answer2) setAnswer2(profile?.answer2);
      if (profile?.answer3) setAnswer3(profile?.answer3);
    });
  }, []);

  const isAnswerValid = (answer) => answer.trim().length >= 3;

  const isFormComplete =
    isAnswerValid(answer1) && isAnswerValid(answer2) && isAnswerValid(answer3);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={96}
      actionDisabled={!isFormComplete}
      action={lets_go}
      actionText={"Weiter"}
    >
      <div className="col-12">
        <h1 className="">Persönliche Fragen</h1>
        <div className="question">
          <div className="task-description-question">
            Erzähle über einen besonderen Moment in den letzten 5 Jahren.
          </div>
          <div className="personal-status mb-3">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Erzähle uns davon"
                aria-label=""
                aria-describedby=""
                maxLength={128}
                onChange={(e) => setAnswer1(e.target.value)}
                value={answer1}
              />
            </div>
            <small className="text-muted">
              {answer1.length}/128 Zeichen verwendet
            </small>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="task-description-question">
          Was ist dein Lieblingsreiseziel?
        </div>
        <div className="personal-status mb-3">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              maxLength={128}
              onChange={(e) => setAnswer2(e.target.value)}
              value={answer2}
            />
          </div>
          <small className="text-muted">
            {answer2.length}/128 Zeichen verwendet
          </small>
        </div>
      </div>
      <div className="col-12">
        <div className="task-description-question">
          Wie sieht dein typisches Wochenende aus?
        </div>
        <div className="personal-status">
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              placeholder="Erzähle uns davon"
              aria-label=""
              aria-describedby=""
              maxLength={128}
              onChange={(e) => setAnswer3(e.target.value)}
              value={answer3}
            />
          </div>
          <small className="text-muted">
            {answer3.length}/128 Zeichen verwendet
          </small>
        </div>
      </div>
    </RegisterFormLayout>
  );
}
