import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";
import { useEffect } from "react";
import { updateUser } from "../../lib/backendClient";
import { retrieveUser } from "../../lib/backendClient";

export default function Smoking() {
  const navigate = useNavigate();

  const [disabled, setDisabled] = React.useState(true);
  const [smoking, setSmoking] = React.useState();

  const location = useLocation();
  const register_alcohol = async () => {
    let update = await updateUser({ smoking: smoking });
    if (update)
      navigate("/register_alcohol", {
        state: { ...location.state },
      });
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.smoking) {
        setSmoking(profile?.smoking);
        setDisabled(false);
      }
    });
  }, []);

  const options = [
    { title: "Ja", key: "yes" },
    { title: "Manchmal", key: "sometimes" },
    { title: "Nein", key: "no" },
    { title: "Ich möchte diese Information nicht teilen.", key: "secret" },
  ];

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={80}
      actionDisabled={disabled}
      action={register_alcohol}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Rauchst du?</h1>
        <div className="task-description">
          Bitte gib an, ob du rauchst oder nicht. Diese Information hilft dabei,
          Gemeinsamkeiten oder Unterschiede in Lebensgewohnheiten zu erkennen.
        </div>
      </div>
      <div className="personal-status">
        {options.map(({ title, key }, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id={`flexRadioDefault${key}`}
              onChange={() => {
                setSmoking(key);
                setDisabled(false);
              }}
              checked={key === smoking}
            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault${key}`}
            >
              {title}
            </label>
          </div>
        ))}
      </div>
    </RegisterFormLayout>
  );
}
