import React, { useEffect, useState } from "react";
import { retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Payment() {
  const [profile, setProfile] = useState(null);
  const [price, setPrice] = useState(null);
  const [stripeButtonId, setStripeButtonId] = useState(null);

  // Get the publishable key from the environment variable
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripeButtonIdCHF = process.env.REACT_APP_STRIPE_BUTTON_ID_CHF;
  const stripeButtonIdEUR = process.env.REACT_APP_STRIPE_BUTTON_ID_EUR;

  useEffect(() => {
    // Retrieve Stripe buy button script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    // Retrieve user profile
    retrieveUser().then((profile) => {
      setProfile(profile);
      if (profile.location_country_code === "CH") {
        setPrice("CHF 29.99");
        setStripeButtonId(stripeButtonIdCHF);
      } else {
        setPrice("EUR 19.99");
        setStripeButtonId(stripeButtonIdEUR);
      }
    });

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={88}
      isActionButtonShown={false}
    >
      <div className="col-12">
        <h1 className="">Werde Paalam-Mitglied</h1>
        <div className="task-description">
          Bald kannst du dich auf neue, flexible Abos freuen.
        </div>
      </div>
      <div className="col-12">
        <div className="subscription-option">
          <div className="subscription-title"></div>
          <div className="subscription-pricing">
            <div className="subscription-price">{price}</div>
            <div className="subscription-unit">/ Monat</div>
          </div>
          <div className="subscription-price-details">
            Gesamtpreis exkl. MwSt.
          </div>
          <div className="subscription-details">
            <p>
              Vertragslaufzeit: unbestimmt<br></br>
              Kündigungsfrist: 1 Monat
            </p>
          </div>
          <ol className="subscription-information">
            <li className="subscription-information-element">
              Verifizierte Profile
            </li>
            <li className="subscription-information-element">
              Unlimitiertes Matchen
            </li>
            <li className="subscription-information-element">
              Personalisierte Matchanfragen
            </li>
            <li className="subscription-information-element">
              Erweiterte Suchfilter
            </li>
            <li className="subscription-information-element">
              Unbegrenztes Messaging
            </li>
            <li className="subscription-information-element">
              Exklusive Workshops und Webinare
            </li>
            <li className="subscription-information-element">
              Premium Support
            </li>
          </ol>
        </div>
        <p className="subscription-contract-details">
          Dein Profil wird durch eine Identitätsprüfung verifiziert. Durch die
          Zahlung akzeptierst du unsere{" "}
          <a
            href="https://paalam-tamil.com/de/agb/"
            target="_blank"
            rel="noopener noreferrer"
          >
            AGB
          </a>{" "}
          und
          <a
            href="https://paalam-tamil.com/de/datenschutz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            Datenschutzbestimmung
          </a>
          .
        </p>
        <div className="text-center">
          {/* Conditionally render Stripe Buy Button based on the profile location */}
          {stripeButtonId && (
            <stripe-buy-button
              buy-button-id={stripeButtonId}
              publishable-key={stripePublishableKey}
              customer-email={profile.email}
            ></stripe-buy-button>
          )}
        </div>
      </div>
    </RegisterFormLayout>
  );
}

<script async src="https://js.stripe.com/v3/buy-button.js"></script>;
