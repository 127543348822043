import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";
import { useEffect } from "react";
import { retrieveUser } from "../../lib/backendClient";
import { updateUser } from "../../lib/backendClient";

export default function Height() {
  const navigate = useNavigate();
  const location = useLocation();

  const register_religion = async () => {
    let update = await updateUser({ height: height });
    if (update)
      navigate("/register_religion", {
        state: { ...location.state },
      });
  };

  const [height, setHeight] = React.useState(170);

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.height) {
        setHeight(profile?.height);
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={53}
      actionDisabled={false}
      action={register_religion}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Wie gross bist du?</h1>
        <div className="task-description">
          Bitte gib deine Körpergröße an. Diese Information hilft anderen, ein
          besseres Bild von dir zu bekommen.
        </div>
      </div>
      <div className="input-group distance mb-3">
        <label htmlFor="userHeight" className="form-label">
          <div>Körpergrösse</div>
          <div className="distance">{height}cm</div>
        </label>
        <input
          type="range"
          className="form-range"
          min="120"
          max="220"
          step="1"
          id="userHeight"
          value={height}
          onChange={(e) => setHeight(e.target.value)}
        />
      </div>
    </RegisterFormLayout>
  );
}
