import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Error404() {
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const mainPage = () => {
    navigate("/"); // Navigate to the "About" page when button is clicked
  };

  const back = () => navigate(-1);

  return (
    <div className="d-flex align-items-center welcome">
      <div className="sign-in w-60 m-auto">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1 className="text-center">Error 404</h1>
              <h2>
                Leider konnten wir die angefragte Seite nicht finden... mit
                deinen Matches läuft es hoffentlich besser 🥹
              </h2>
              <div className="choice-options">
                <button onClick={back} className="btn btn-primary py-2">
                  zurück
                </button>
                <button onClick={mainPage} className="btn btn-secondary py-2">
                  Zur Startseite
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
