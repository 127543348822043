import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadLanguages } from "../../../lib/backendClient";
import { useEffect } from "react";
import { retrieveUser } from "../../../lib/backendClient";
import { updateUser } from "../../../lib/backendClient";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditLanguages() {
  const navigate = useNavigate();

  const location = useLocation();

  const register_education = async () => {
    let update = await updateUser({ languages: [...languagesState] });
    return update;
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [languagesState, setLanguagesState] = React.useState(new Set());

  const addLanguage = (language) => {
    setLanguagesState((prevLanguages) => {
      const updatedLanguages = new Set(prevLanguages);

      if (updatedLanguages.has(language)) {
        updatedLanguages.delete(language);
      } else {
        updatedLanguages.add(language);
      }

      return updatedLanguages;
    });
  };

  useEffect(() => {
    loadLanguages().then((languages) => {
      setAvailableOptions(languages);
    });
    retrieveUser().then((profile) => {
      if (profile?.languages) {
        setLanguagesState(new Set(profile?.languages));
      }
    });
  }, []);

  return (
    <EditFormLayout
      title="Sprachen"
      actionDisabled={languagesState.size === 0}
      action={register_education}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Welche Sprachen sprichst du?</h1>
        <div className="task-description">
          Bitte gib an, welche Sprachen du sprichst. Diese Information hilft
          dabei, die Kommunikation mit potenziellen Partnern zu erleichtern und
          sicherzustellen, dass ihr eine gemeinsame Sprachbasis habt.
        </div>
      </div>
      <div className="hstack flex-wrap mt-2 gap-2">
        {avaliableOptions.map((option) => (
          <button
            key={`${option.id}-${languagesState.has(option.id)}`}
            className={`btn ${languagesState.has(option.id) ? "btn-primary" : "btn-secondary"} py-2`}
            onClick={(event) => {
              addLanguage(option.id);
            }}
          >
            {option.name} {option.symbol}
          </button>
        ))}
      </div>
    </EditFormLayout>
  );
}
