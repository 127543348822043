import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../lib/backendClient";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Distance() {
  const navigate = useNavigate();

  const [distance, setDistance] = React.useState(300);
  const location = useLocation();

  const create_pictures = async () => {
    let update = await updateUser({ distance: distance });
    if (update)
      navigate("/create_pictures", {
        state: { ...location.state },
      }); // Navigate to the "About" page when button is clicked
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.distance) {
        setDistance(profile?.distance);
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={66}
      actionDisabled={false}
      action={create_pictures}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Suchradius festlegen</h1>
      </div>
      <div className="input-group mb-3 distance">
        <label htmlFor="customRange3" className="form-label">
          <div>Distanz</div>
          <div className="distance">{distance}km</div>
        </label>
        <input
          type="range"
          className="form-range"
          min="0"
          max="600"
          step="10"
          id="customRange3"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
        />
      </div>
    </RegisterFormLayout>
  );
}
