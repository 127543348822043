import React, { useState, useRef } from "react";

const VoiceMessagePlayer = ({ audioSrc }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  };

  return (
    <div style={styles.container}>
      <button onClick={togglePlayPause} style={styles.button}>
        {isPlaying ? (
          <i className="bi fs-1 bi-pause-circle" />
        ) : (
          <i className="bi fs-1 bi-play-circle" />
        )}
      </button>
      <audio
        id="audio-element"
        src={audioSrc} // Ensure this points to a valid WAV file
        onPlay={handlePlay}
        onPause={handlePause}
        onError={() =>
          console.error("Failed to load audio. Please check the source.")
        }
        ref={audioRef}
      >
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  button: {
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "100%",
  },
};

export default VoiceMessagePlayer;
