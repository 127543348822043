import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";
import { useEffect } from "react";
import { updateUser } from "../../lib/backendClient";
import { retrieveUser, loadCuisines } from "../../lib/backendClient";

export default function Kitchen() {
  const navigate = useNavigate();

  const location = useLocation();
  const register_questions = async () => {
    let update = await updateUser({
      cuisines: [...cuisines],
    });
    if (update)
      navigate("/register_questions", {
        state: { ...location.state },
      });
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [cuisines, setCuisines] = React.useState(new Set());

  const addCuisine = (cuisine) => {
    setCuisines((prevCuisine) => {
      const updatedCuisine = new Set(prevCuisine);

      if (updatedCuisine.has(cuisine)) {
        updatedCuisine.delete(cuisine);
      } else {
        if (updatedCuisine.size < 3) updatedCuisine.add(cuisine);
      }

      return updatedCuisine;
    });
  };

  useEffect(() => {
    loadCuisines().then((cuisine) => {
      setAvailableOptions(cuisine);
    });
    retrieveUser().then((profile) => {
      if (profile?.cuisines) {
        setCuisines(new Set(profile?.cuisines));
      }
    });
  }, []);

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      progress={93}
      actionDisabled={cuisines.size === 0}
      action={register_questions}
      actionText={"Weiter"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Lieblingsküchen</h1>
        <div className="task-description">
          Wähle bis zu drei bevorzugten Küchen aus, die deinem Geschmack
          entsprechen.
        </div>
      </div>
      <div className="hstack flex-wrap mt-2 gap-2">
        {avaliableOptions.map((option) => (
          <button
            key={`${option.id}-${cuisines.has(option.id)}`}
            className={`btn ${cuisines.has(option.id) ? "btn-primary" : "btn-secondary"} py-2`}
            onClick={(event) => {
              addCuisine(option.id);
            }}
          >
            {option.name}
          </button>
        ))}
      </div>
    </RegisterFormLayout>
  );
}
