import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { updateUser } from "../../../lib/backendClient";
import { retrieveUser } from "../../../lib/backendClient";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditKids() {
  const navigate = useNavigate();

  const [disabled, setDisabled] = React.useState(true);
  const [kids, setKids] = React.useState();
  const location = useLocation();
  const register_childrenWish = async () => {
    let update = await updateUser({ kids: kids });
    return update;
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.kids) {
        setKids(profile?.kids);
        setDisabled(false);
      }
    });
  }, []);

  const options = [
    { title: "Ich habe Kinder.", key: "yes" },
    { title: "Ich habe keine Kinder", key: "no" },
    { title: "Ich möchte diese Information nicht teilen.", key: "secret" },
  ];

  return (
    <EditFormLayout
      title="Kinder"
      actionDisabled={disabled}
      action={register_childrenWish}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Kinder?</h1>
        <div className="task-description">
          Kinderwünsche, eigene Kinder und Kinder des Partners spielen sehr
          häufig eine grosse Rolle.
        </div>
      </div>
      <div className="personal-status">
        {options.map(({ title, key }, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id={`flexRadioDefault${key}`}
              onChange={() => {
                setKids(key);
                setDisabled(false);
              }}
              checked={key === kids}
            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault${key}`}
            >
              {title}
            </label>
          </div>
        ))}
      </div>
    </EditFormLayout>
  );
}
