import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  passwordReset,
  login,
  retrieveUser,
  retrieveProfilePictures,
} from "../lib/backendClient";
import RegisterFormLayout from "./layout/registerFormLayout";
import { getNavigationPath } from "../lib/userRouting";

import { z } from "zod";

const emailSchema = z
  .string()
  .email({ message: "Bitte eine gültige E-Mail-Adresse angeben." });

function Login() {
  const [email, setMail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mailError, setMailError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleMailChange = (event) => {
    const inputEmail = event.target.value;
    setMail(inputEmail);

    try {
      emailSchema.parse(inputEmail);
      setMailError(null);
    } catch (error) {
      if (error instanceof z.ZodError) {
        setMailError(error.errors[0].message); // Show password validation error
      }
    }
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const navigate = useNavigate();

  const handleLogin = async () => {
    let successLogin = await login(email, password);
    if (successLogin) {
      let user = await retrieveUser();
      let profilePictures = await retrieveProfilePictures();
      const path = getNavigationPath(user, profilePictures);
      navigate(path, { state: { email: user.email } });
    }
  };

  const handlePasswordReset = async () => {
    try {
      emailSchema.parse(email);
      setMailError(null); // No error if validation passes
      await passwordReset(email);
    } catch (error) {
      if (error instanceof z.ZodError) {
        setMailError(error.errors[0].message); // Show password validation error
      }
    }
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={true}
      actionDisabled={mailError}
      action={handleLogin}
      actionText={"Login"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Willkommen zurück</h1>
        <div className="task-description">
          Gib deine E-Mail-Adresse und dein Passwort ein.
        </div>
        <label htmlFor="" className="form-label">
          E-Mail
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="mail">
            <i className="bi bi-envelope-fill"></i>
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="E-Mail"
            aria-label="E-Mail"
            aria-describedby="basic-addon1"
            value={email}
            onChange={handleMailChange}
          />
        </div>
        {mailError && <div className="alert alert-danger">{mailError}</div>}
        <label htmlFor="" className="form-label">
          Passwort
        </label>
        <div className="input-group mb-3">
          <span className="input-group-text" id="password">
            <i className="bi bi-lock-fill"></i>
          </span>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            placeholder="Passwort"
            aria-label="Passwort"
            aria-describedby="basic-addon1"
            value={password}
            onChange={handlePasswordChange}
          />
          <div className="input-group-text" onClick={toggleShowPassword}>
            <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"}`} />
          </div>
        </div>
        <div className="text-center">
          <p
            className="password-reset"
            onClick={handlePasswordReset}
            disabled={mailError}
          >
            Passwort vergessen?
          </p>
        </div>
        <hr />
        <div className="d-flex justify-content-center">
          <p className="account-create">Noch kein Account?</p>
          <Link to="/register">Registrieren</Link>
        </div>
      </div>
    </RegisterFormLayout>
  );
}

export default Login;
