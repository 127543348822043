import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import RegisterFormLayout from "../layout/registerFormLayout";

export default function Create_Summary() {
  const navigate = useNavigate();

  const finish = () => {
    alert("Prelaunch erfolgreich abgeschlossen!");
    navigate("/");
  };

  return (
    <RegisterFormLayout
      isNavigationBackShown={false}
      actionDisabled={false}
      action={finish}
      actionText={"Zur Startseite"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">Übersicht</h1>
        <div className="task-description">
          Vielen Dank für deine Registrierung bei Paalam!
        </div>
        <div className="task-description">
          Deine Anmeldung ist erfolgreich. Wir halten dich auf dem Laufenden!
        </div>
        <div className="task-description">
          Am 31.10.2024 startet Paalam offiziell. Ab dann kannst du alle
          Funktionen nutzen, um echte Verbindungen zu knüpfen und deine
          Partnersuche zu beginnen.
        </div>
        <div className="task-description">Bis dahin – bleib gespannt!</div>
      </div>
    </RegisterFormLayout>
  );
}
