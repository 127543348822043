import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import { blockUser, reportUser } from "../../lib/backendClient";

export default function ChatOptions() {
  const location = useLocation();
  const { chat } = location.state || {};

  const navigate = useNavigate();

  const block = () => {
    blockUser(chat?.id);
    alert("Nutzer wurde blockiert");
    navigate("/chats");
  };

  const report = () => {
    reportUser(chat?.id);
    alert("Nutzer wurde gemeldet");
    navigate("/chats");
  };

  return (
    <AppLayout
      title={"Optionen"}
      isNavigationBackShown={true}
      onNavigation={() => navigate(-1)}
      //isSettingsButtonShown={false}
      //settingsIcon="bi-sliders"
      //settingsAction,
    >
      <div className="row swipe-view g-2 mb-4">
        <div className="col-12">
          <div className="filters">
            <button
              onClick={() => block()}
              className="chat-action"
              type="button"
            >
              <div className="chat-action-icon">
                <i className="bi bi-flag"></i>
              </div>
              <div className="chat-action-item">
                <div className="chat-action-title">
                  Match auflösen und blockieren
                </div>
                <div className="chat-action-subline">
                  Wir informieren den Nutzer darüber, dass der Match aufgelöst
                  wude.
                </div>
              </div>
            </button>
            <button
              onClick={() => report()}
              className="chat-action"
              type="button"
            >
              <div className="chat-action-icon">
                <i className="bi bi-ban"></i>
              </div>
              <div className="chat-action-item">
                <div className="chat-action-title">Nutzer melden</div>
                <div className="chat-action-subline">
                  Wir informieren dein Nutzer nicht darüber, wer ihn/sie
                  gemeldet hat.
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
