import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  loadPersonalities,
  updateUser,
  retrieveUser,
} from "../../../lib/backendClient";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditPersonality() {
  const navigate = useNavigate();

  const [personalities, setPersonalities] = React.useState(new Set());

  const location = useLocation();

  const register_kitchen = async () => {
    let update = await updateUser({ personality_traits: [...personalities] });
    return update;
  };

  const addPersonality = (personality) => {
    setPersonalities((prevPersonalities) => {
      const updatedPersonalities = new Set(prevPersonalities);

      if (updatedPersonalities.has(personality)) {
        updatedPersonalities.delete(personality);
      } else {
        if (updatedPersonalities.size < 3)
          updatedPersonalities.add(personality);
      }

      return updatedPersonalities;
    });
  };

  const filter = (event) => {
    setFilteredOptions(
      avaliableOptions.filter((option) =>
        option.name.startsWith(event.target.value),
      ),
    );
  };

  const [avaliableOptions, setAvailableOptions] = React.useState([]);
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  useEffect(() => {
    loadPersonalities().then((loadedPersonalities) => {
      setAvailableOptions(loadedPersonalities);
      setFilteredOptions(loadedPersonalities);
    });
    retrieveUser().then((profile) => {
      if (profile?.personality_traits) {
        setPersonalities(new Set(profile?.personality_traits));
      }
    });
  }, []);

  return (
    <EditFormLayout
      title="Persönlichkeitsmerkmale"
      actionDisabled={personalities.size === 0}
      action={register_kitchen}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <img src="" className="mb-4" />
        <h1 className="">
          Finde Personen mit ähnlichen Persönlichkeitsmerkmalen
        </h1>
        <div className="task-description">
          Wähle Persönlichkeitsmerkmalen, die dich beschreiben.
        </div>
      </div>
      <div className="input-group mb-3">
        <span className="input-group-text" id="mail">
          <i className="bi bi-search"></i>
        </span>
        <input
          type="text"
          className="form-control"
          placeholder="Persönlichkeitsmerkmale suchen"
          aria-label="personalities"
          aria-describedby="personalities"
          onChange={filter}
        />
      </div>
      <div className="hstack flex-wrap mt-2 gap-2">
        {filteredOptions.map((option) => (
          <button
            key={`${option.id}-${personalities.has(option.id)}`}
            className={`btn ${personalities.has(option.id) ? "btn-primary" : "btn-secondary"} py-2`}
            onClick={(event) => {
              addPersonality(option.id);
            }}
          >
            {option.name} {option.symbol}
          </button>
        ))}
      </div>
    </EditFormLayout>
  );
}
