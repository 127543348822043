import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUser, retrieveUser } from "../../../lib/backendClient";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditStatus() {
  const navigate = useNavigate();

  const [disabled, setDisabled] = React.useState(true);
  const [status, setStatus] = React.useState();
  const location = useLocation();

  const register_children = async () => {
    let update = await updateUser({ marital_status: status });
    return update;
  };

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.marital_status) {
        setStatus(profile?.marital_status);
        setDisabled(false);
      }
    });
  }, []);

  const options = [
    { title: "noch nie verheiratet", key: "single" },
    { title: "geschieden", key: "divorced" },
    { title: "getrennt", key: "separated" },
    { title: "verwitwet", key: "widow" },
    { title: "Ich möchte diese Information nicht teilen.", key: "secret" },
  ];

  return (
    <EditFormLayout
      title="Lebensstatus"
      actionDisabled={disabled}
      action={register_children}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1>Wie ist dein Familienstand?</h1>
        <div className="task-description">
          Bitte gib deinen Familienstand an. Diese Information hilft dabei, ein
          klareres Bild deiner aktuellen Lebenssituation zu vermitteln.
        </div>
      </div>
      <div className="personal-status">
        {options.map(({ title, key }, index) => (
          <div className="form-check" key={index}>
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id={`flexRadioDefault${key}`}
              onChange={() => {
                setStatus(key);
                setDisabled(false);
              }}
              checked={key === status}
            />
            <label
              className="form-check-label"
              htmlFor={`flexRadioDefault${key}`}
            >
              {title}
            </label>
          </div>
        ))}
      </div>
    </EditFormLayout>
  );
}
