import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import { updateUser } from "../../../lib/backendClient";
import { retrieveUser } from "../../../lib/backendClient";
import { useEffect } from "react";
import EditFormLayout from "../../layout/editFormLayout";

export default function EditProfession() {
  const navigate = useNavigate();

  const location = useLocation();
  const register_smoking = async () => {
    let update = await updateUser({
      profession: profession,
    });
    return update;
  };

  const [profession, setProfession] = useState("");

  useEffect(() => {
    retrieveUser().then((profile) => {
      if (profile?.profession) {
        setProfession(profile.profession);
      }
    });
  }, []);

  const isAnswerValid = (profession) => profession.trim().length >= 3;

  const isFormComplete = isAnswerValid(profession);

  return (
    <EditFormLayout
      title="Beruf"
      actionDisabled={!isFormComplete}
      action={register_smoking}
      actionText={"Ok"}
    >
      <div className="col sign-in w-100 m-auto">
        <h1 className="">Welchen Beruf übst du aus?</h1>
        <div className="task-description">
          Deine Berufsbezeichnung ist für andere Mitglieder sichtbar. Achte
          bitte auf eine korrekte Schreibweise und verwende eindeutige Aussagen
          wie Projektmanager Digital statt Manager.
        </div>
      </div>
      <div className="personal-status">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder="Berufsbezeichnung"
            aria-label=""
            aria-describedby=""
            maxLength={64}
            onChange={(e) => setProfession(e.target.value)}
            value={profession}
          />
        </div>
      </div>
    </EditFormLayout>
  );
}
