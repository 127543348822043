import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppLayout from "../layout/appLayout";
import {
  loadReligions,
  retrieveUser,
  updateUser,
} from "../../lib/backendClient";

export default function SwipeFilter() {
  const [distance, setDistance] = useState(300);
  const [minAge, setMinAge] = useState(25);
  const [maxAge, setMaxAge] = useState(40);
  const [religions, setReligions] = useState(new Set());
  const [options, setOptions] = useState([]);

  const addReligion = (religion) => {
    setReligions((prevReligions) => {
      const updatedReligions = new Set(prevReligions);
      if (updatedReligions.has(religion)) {
        updatedReligions.delete(religion);
      } else {
        updatedReligions.add(religion);
      }
      return updatedReligions;
    });
  };

  const saveFilter = async () => {
    let update = await updateUser({
      distance: distance,
      min_age_preference: minAge,
      max_age_preference: maxAge,
      religions_preference: [...religions],
    });
    return update;
  };

  useEffect(() => {
    loadReligions().then((reli) => setOptions(reli));
    retrieveUser().then((profile) => {
      if (profile?.distance) setDistance(profile?.distance);
      if (profile?.min_age_preference) setMinAge(profile?.min_age_preference);
      if (profile?.max_age_preference) setMaxAge(profile?.max_age_preference);
      if (profile?.religions_preference)
        setReligions(new Set(profile?.religions_preference));
    });
  }, []);

  // Style calculation for slider range background
  const sliderRangeStyle = {
    left: `${((minAge / 100) * 100).toFixed(2)}%`,
    width: `${(((maxAge - minAge) / 100) * 100).toFixed(2)}%`,
  };

  return (
    <AppLayout
      title={"Suchfilter"}
      isNavigationBackShown={true}
      onNavigation={saveFilter}
    >
      <div className="row swipe-view g-2 mb-4">
        <div className="col-12">
          <div className="filters">
            <div className="input-group mb-3 distance">
              <label for="customRange3" className="form-label">
                <div>Distanz</div>
                <div className="distance">{distance}</div>
              </label>
              <div className="slider-container form-range" id="rangeSlider">
                <div className="slider-track"></div>
                <input
                  type="range"
                  min="0"
                  max="600"
                  step="10"
                  value={distance}
                  className="slider"
                  onChange={(e) => setDistance(e.target.value)}
                />
              </div>
            </div>
            <div className="input-group mb-3 age-range">
              <label htmlFor="customRange3" className="form-label">
                <div>Alter</div>
                <div className="distance">
                  {minAge} - {maxAge}
                </div>
              </label>
              <div className="slider-container form-range" id="rangeSlider">
                <div className="slider-track"></div>
                <div className="slider-range" style={sliderRangeStyle}></div>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={minAge}
                  className="slider"
                  onChange={(e) => {
                    const newMinAge = parseInt(e.target.value, 10);
                    if (newMinAge >= 25 && newMinAge <= maxAge)
                      setMinAge(newMinAge);
                  }}
                />
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={maxAge}
                  className="slider"
                  onChange={(e) => {
                    const newMaxAge = parseInt(e.target.value, 10);
                    if (newMaxAge >= minAge) setMaxAge(newMaxAge);
                  }}
                />
              </div>
            </div>

            <div className="input-group mb-3 distance">
              <label htmlFor="religions" className="form-label">
                <div>Religion</div>
              </label>
              <div className="hstack flex-wrap gap-2" id="religions">
                {options.map((option, index) => (
                  <button
                    key={`${option.id}-${religions.has(option.id)}`}
                    onClick={() => addReligion(option.id)}
                    className={`btn ${religions.has(option.id) ? "btn-primary" : "btn-secondary"}`}
                  >
                    {option.name} {option.symbol}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
